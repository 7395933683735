import { defaultLocale } from "./canonicalSettings";

import nl from "@/locales/generated/nl";
import fr from "@/locales/generated/fr";
import en from "@/locales/generated/en";

export default defineI18nConfig(() => ({
  legacy: false,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
  fallbackWarn: false,
  missingWarn: false,
  messages: {
    nl: nl,
    fr: fr,
    en: en,
  },
}));
